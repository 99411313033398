import * as countries from 'i18n-iso-countries';
import { EmailDummyData } from '../constants/EmailDummyData';
import { getBankAccountsByAccountID } from '../networking/NetworkingBankAccounts';
import { getParametersByName } from '../networking/NetworkingGlobalSystemParameters';
import { notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../styles/styles';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export function formatToCurrency(amount){
    return parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function formatToCommaSeparate(amount) {
    if (amount != null && amount > 0) {
        return amount.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",");
    } return amount;
}
//module.exports.formatToCurrency = formatToCurrency;

export const formatToRate = (rate, decimalDigits = 4) => {
    return parseFloat(rate).toFixed(decimalDigits).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
//module.exports.formatToRate = formatToRate;

export function formatDateToDDMMYYYY(date){
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
}

export function formatDateString(dateString){
    const d = new Date(dateString);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
//module.exports.formatDate = formatDate;

export function formatDate(date){
    if (date == null) {
        return '';
    }
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
}

export const formatStringToDate = (string) => {
    const [year, month, day] = string.split('/');
    const date = new Date(year, Number(month) - 1 , day)
    return date;
}

function addZero(i) {
    if (i < 10) {
    i = "0" + i;
    }
    return i;
}

export function getHoursDate(dateString) {
    var d = new Date(dateString);

    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());

    return [h,m,s].join(':');
}

export function formatDatetime(date){
    const d = new Date(date);
    let  month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    var dateTest = `${year}-${month}-${day} ${h}:${m}:${s}.000`
    return dateTest;
}
//module.exports.getHoursDate = getHoursDate;

export function toDDMMMFormat(stringDate){
    var date = new Date(stringDate);
    var month_names =["Jan","Feb","Mar",
                      "Apr","May","Jun",
                      "Jul","Aug","Sep",
                      "Oct","Nov","Dec"];
    var day = date.getDate();
    var month_index = date.getMonth();
    //var year = date.getFullYear();
    return month_names[month_index] + " " + day;
}
//module.exports.toDDMMMFormat = toDDMMMFormat;

export function getYear(stringDate){
    var date = new Date(stringDate);
    return date.getFullYear();
}
//module.exports.getYear = getYear;

export function getCountryName(isoCode){
    return countries.getName(isoCode,'en');
}

export function titleCase(str) {
    if (str.includes(' ')){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        return splitStr.join(' ');
    } else {
        return str;
    } 
}

export function addDays(date, days) {
    var result = date;
    result.setDate(date.getDate() + days);
    return result;
}

export const removeWhiteSpace = (string) => string.replace(/\s/g, '');

export const formatValidSelect = (valid) => valid ? regularSelectClass : notFilledSelectClass;

export const formatValidInput = (valid) => valid ? regularInputClass : notFilledInputClass;

export const formatValidDatePicker = (valid) => valid ? regularInputClass : notFilledInputClass;

export const formatStringToBool = (string) => {
    if (string && typeof string === 'string') {
        if (string.toLowerCase() === 'true') return true;
        if (string.toLowerCase() === 'false') return false;
    }
    return string;
}

export const formatToMT103 = async (model) => {
    const date = new Date();
    const [month, day, year] = formatDateToDDMMYYYY(date);
    // Information for designing this message is found in FXClient-6.12.2\FxFoundation\Utility\MT103MessageBuilder.cs:InsertMT103Message
    let list = [
        `:20:${model.dealID}-${model.outgoingFund}\n`,
        ':23B:CRTS\n',
        `:32A:${year}${month}${day}${model.currencyID}${model.paymentAmount.toFixed(2).toString().replace('.', ',')}\n`,
        `:50A/${model.nostroAccountName}\n`,
        `1/${model.beneficiaryName}\n`,
        `2/${model.beneficiaryAddress}\n`,
        `3/${model.countryID}/${model.beneficiaryState}\n`,
        ':71A:BEN'
    ]
    const bankAccount = await getBankAccountsByAccountID(model.beneficiaryBankAccountID);
    if (bankAccount != null && bankAccount['root'] != null && bankAccount['root'].length > 0) {
        if (bankAccount['root'][0]['Account No'] != null && bankAccount['root'][0]['Account No'] !== '') {
            list.splice(4, 0, `:59A:/:${bankAccount['root'][0]['Account No']}\n`);
        }
        if (bankAccount['root'][0].SWIFT != null && bankAccount['root'][0].SWIFT !== '') {
            list.splice(4, 0, `:57A:${bankAccount['root'][0].SWIFT}\n`);
        }
        if (model.interBank) {
            list.splice(4, 0, `:53A:/${model.interBank}\n`);
        }
    }
    // Required for Blob
    let text = ''
    list.forEach(e => text += e);
    return text;
}

export const formatDateToMMDDYY = (date) => {
    const year = date.getFullYear() % 100;
    const yearFormatted = (year) < 10 ? `0${year}` : year;
    const month = date.getMonth() + 1;
    const monthFormatted = (month) < 10 ? `0${month}` : month;
    const day = date.getDate();
    const dayFormatted = (day) < 10 ? `0${day}` : day;
    return [monthFormatted, dayFormatted, yearFormatted];
}

export function getTimehhmm(datetime){
    let hours = datetime.getHours();
    let minutes = datetime.getMinutes();
    var hoursText = hours;
    var minutesText = minutes;
    if(hours < 10){
        hoursText = '0' + hours;
    }
    if(minutes < 10){
        minutesText = '0' + minutes;
    }
    return hoursText + ':' + minutesText;
}

export function replaceWithDummyData(body) {
    let keys = Object.keys(EmailDummyData);
    keys.forEach((value) => {
        body = body.replaceAll(value, EmailDummyData[value]);
    });
    return body;
}

export async function getEmailFooter(){
    
    let response = await getParametersByName('CompanyName,CompanyAddress,CompanyTelephoneNumber,CompanyFaxNumber,CompanyWebsite');
    let parameters = response.parameters;
    
    let FOOTER = `
    <br/>
    <div>
        <p><strong>$CompanyName</strong></p>
        <p>$CompanyAddress</p>
        <p><strong>Phone: </strong>$CompanyTelephoneNumber</p>
        <p><strong>Fax: </strong>$CompanyFaxNumber</p>
        <p><a style="color:#9678b8;font-size:11px;line-height: 150%;font-family:Arial, Helvetica, sans-serif; " href='$CompanyWebsite'>$CompanyWebsite</a></p>
    </div>
    `;

    parameters.forEach((value)=>{
        FOOTER = FOOTER.replaceAll('$'+ value.Name, value.Value);
    });

    return FOOTER;
}

export function getScaleByCurrency(currency) {
    if (currency != null && currency != '') {
        let currencies = JSON.parse(localStorage.getItem('Currencies'));
        var ccy = currencies.find(c=> c["Currency Id"] == currency);
        if(ccy != null)
        {
            return ccy.Scale;
        }
        return 2;
    } return 2;
}

export function getScaleForCurrencyPair(buyCurrency, sellCurrency) {
    let currencyPairs = JSON.parse(localStorage.getItem('CurrencyPairs'));
    let pair = currencyPairs.find(c => c.CurrencyCode === buyCurrency && c.OutCurrencyCode === sellCurrency);
    return pair != null ? pair.RatePrecision : 8
}


export function getPlaceHolder(numberOfDecimals) {
    let ph = "_,";
    while (ph.length < numberOfDecimals + 2) {
        ph = ph + "0";
    }
    return ph;
}

export function formatToCurrencyWithScale(amount, currency){
    var scale = getScaleByCurrency(currency);
    var result = amount; 
    if (amount != null) {
        let amountWithoutThousand = amount.toString().replace(',','');
        let amountNumber = Number(amountWithoutThousand);
        if (!Number.isNaN(amountNumber)) {
            /*if (amount.toString().includes(',')) {
                result =  parseFloat(amount.replace(/[,]+/g, '')).toFixed(scale);
            }
                result = parseFloat(amountNumber).toFixed(scale).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            */
            result = parseFloat(amountNumber).toFixed(scale).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            //result = parseFloat(amountNumber).toFixed(scale).replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
        }
    }
    
    return result;
}

export function roundAmountByCurrency(amount, currency) {

    let amountNumber = Number(amount)
    let scale = 2
    if (currency != null && currency != '') {
        let currencies = JSON.parse(localStorage.getItem('Currencies'));
        var ccy = currencies.find(c => c["Currency Id"] == currency);
        if (ccy != null) {
            scale = ccy.Scale;
        }
    }
    return parseFloat(amountNumber).toFixed(scale)
}

export function roundAmountByCurrencyPair(amount, buyCurrency, sellCurrency) {

    let amountNumber = Number(amount)
    let currencyPairs = JSON.parse(localStorage.getItem('CurrencyPairs'));
    let pair = currencyPairs.find(c => c.CurrencyCode === buyCurrency && c.OutCurrencyCode === sellCurrency);
    let scale = pair != null ? pair.RatePrecision || 8 : 8;
    
    return parseFloat(amountNumber).toFixed(scale).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function downloadTemplateCSV(obj, fileName) {
    let csvContent = "data:text/plain;charset=utf-8,";
    let csvDataAsRows = obj
    if (csvDataAsRows && csvDataAsRows.length > 0) {
    let header = [...Object.keys(csvDataAsRows[0])];
    for (let i = 0; i < csvDataAsRows.length; i++) {
      delete csvDataAsRows[i].Action;
      delete csvDataAsRows[i].Key;
  
      for (let j = 0; j < header.length; j++) {
        if (header[j].includes('$')) {
          delete csvDataAsRows[i][header[j]];
        }
      }
    }
    let encodedUri = '';
    let link;
  
    csvDataAsRows = obj != null ?
      obj
        .map((e) => Object.values(e))
        .reduce(function (csvString, row) {
          csvString += "\r\n";
          csvString += row.join(',');
          return csvString;
        }) : [];
    let today = new Date();
    let yearString = today.getFullYear().toString();
    let monthString = (today.getMonth() + 1).toString().padStart(2, '0');
    let dayString = today.getDate().toString().padStart(2, '0');
    let hourString = today.getHours().toString().padStart(2, '0');
    let minuteString = today.getMinutes().toString().padStart(2, '0');
    let timeStamp = yearString + monthString + dayString + hourString + minuteString;
  
    let newFileName = fileName + timeStamp + ".csv";
  
    encodedUri = encodeURI(csvContent + header + "\r\n" + csvDataAsRows);
    link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", newFileName);
    link.click();
    document.body.appendChild(link);
    }
  }