import React from 'react';
import { downloadTemplateCSV, formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrency } from '../../../helpers/FormatHelper';
import { getBeneficiaryForDisbursementsScreen } from '../../../networking/NetworkingBeneficiaries';
import { getAmountInBaseCCy } from '../../../networking/NetworkingCurrencies';
import { calculateWireItemFee } from '../../../networking/NetworkingFees';
import { getUserDefinedPropertiesBySubEntityType } from '../../../networking/NetworkingUserDefinedProperties';
import { notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DisbursmentPropertiesDialog from '../../shared/Dialogs/DisbursmentPropertiesDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import NumberInput from '../../shared/NumberInput';
import WizardDealSummary from './WizardDealSummary';
import { IsRestrictedCountry } from '../../../networking/NetworkingCustomers';
import { getListOfPaymentOrderImportRecords, importPaymertOrdersTemplate } from '../../../networking/NetworkingPayments';
import GeneralDragAndDropFileDialog from '../../shared/Dialogs/GeneralDragAndDropFileDialog';

export default class WizardOutgoingFunds extends React.Component {
    state = {
        amountEnableUpdatesTrigger: true,
        beneficiaryList: [],
        beneficiaryDictionary: {},
        beneficiaryAccountList: [],
        beneficiaryAccountsDictionary: {},
        customerRate: 1,
        deliveryMethodList: {},
        globalAlertMessage: '',
        globalAlertTitle: '',        
        outgoingFundList: [],
        outgoingInstrumentList: [],
        outgoingInstrumentListForNonAccounts: [],
        rateTerms: '',
        showBalanceToDisburseAlert: false,
        showForwardAlert: false,
        showGlobalAlert: false,
        showNextAlert: false,
        showDisbursementPropertiesDialog: false,
        disbursementDialogIndex: 0,
        showDisbursementPropertiesAlert: false,
        properties: [],
        selectDisbursementAmount:0,
        beneficiaryID: 0,
        
        showAlert1: false,
        alertTitle1: '',
        alertMessage1: '',
        disbursementImportRecord:[],
        showFileDialog:false,
        fileUploaded: null,
        listErrors:[]
    }

    componentDidMount = async() => {
        //Load customer rate
        let customerRate = this.props.objDealHeader.CustomerRate;
        let rateTerms = this.props.objDealHeader.RateTerms;
        
        var outgoingFundList = this.props.disbursements;
        if(outgoingFundList.length === 0){
            outgoingFundList = this.addDisbursement(outgoingFundList);
        }

        var outgoingFundListFinal = [];
        for(var d=0; d<outgoingFundList.length; d++){
            var helper = outgoingFundList[d];

            var amountBaseCCy = await getAmountInBaseCCy(this.props.objDealHeader.SellCurrency, outgoingFundList[d].PaymentAmount);
            helper.PaymentAmountInBaseCCY = amountBaseCCy.response;
    
            if(helper.ID === undefined)
                {
                    helper.ID = d;
                }    
            outgoingFundListFinal.push(helper);   
        }
        
        //Load delivery methods
        let instruments = this.props.outgoingInstrumentList;
        var outgoingInstrumentListForNonAccounts = [];
        var deliveryMethodList = {};
        for(var i=0; i<instruments.length; i++){
            var list = [];
            if(instruments[i].deliveryMethods !== ''){
                var methodsList = instruments[i].deliveryMethods.split('§');
                for(var j=0; j<methodsList.length; j++){
                    var item = {value:methodsList[j], name:methodsList[j]};
                    list.push(item);
                }
            }
            deliveryMethodList[instruments[i].value] = list;

            //Non account instruments
            if(Number(instruments[i].accountsOnly) === 0){
                outgoingInstrumentListForNonAccounts.push(instruments[i]);
            }
        }
        //alert(JSON.stringify(outgoingInstrumentListForNonAccounts));
        //alert(JSON.stringify(deliveryMethodList));
        //alert(JSON.stringify(this.props.beneficiaryList));
        this.setState({beneficiaryDictionary: this.props.beneficiaryDictionary,
            beneficiaryList: this.props.beneficiaryList,
            customerRate:customerRate,
            outgoingFundList: outgoingFundListFinal,
            outgoingInstrumentList: instruments,
            outgoingInstrumentListForNonAccounts: outgoingInstrumentListForNonAccounts,
            rateTerms: rateTerms,
            deliveryMethodList: deliveryMethodList,
        });

        var beneficiaries = localStorage.getItem('DisbursementBeneficiaries');
        if(beneficiaries !== null &&
            beneficiaries.CustomerID === this.props.objCustomer["Customer ID"] &&
            beneficiaries.CurrencyID === this.props.objDealHeader.SellCurrency){
            this.setState({beneficiaryAccountList: beneficiaries.Items});
        }
        else{
            getBeneficiaryForDisbursementsScreen(this.props.objCustomer["Customer ID"], this.props.objDealHeader.SellCurrency).then(
                (json) => {
                    var helper = [];
                    var helperDictionary = {};
                    let beneficiaries = json.beneficiaries;
                   /* var tbaRecord = {value:'-2|0', name:'TBA', BeneficiaryID:-2, BeneficiaryBankAccountID:0, BeneficiaryStatus: 'Authorized'};
                    helper.push(tbaRecord);
                    helperDictionary[-2] = tbaRecord;*/

                    /*var customerRecord = {value:'-1|0', name:this.props.objCustomer['Customer Name'], BeneficiaryID:-1, BeneficiaryBankAccountID:0, BeneficiaryStatus: 'Authorized'}
                    helper.push(customerRecord);
                    helperDictionary[-1] = customerRecord;*/
                    
                    if(beneficiaries !== undefined){                       
                        for(var i=0; i<beneficiaries.length; i++){
                            if (beneficiaries[i].BeneficiaryStatus === 'Authorized') {
                                beneficiaries[i].value = beneficiaries[i].Value;
                                beneficiaries[i].name = beneficiaries[i].Description;
                                helper.push(beneficiaries[i]);
                                helperDictionary[beneficiaries[i].BankAccountID] = beneficiaries[i];
                            }
                        }
                    }
                    //alert(JSON.stringify(helper));
                    this.setState({beneficiaryAccountList: helper,
                        beneficiaryAccountsDictionary: helperDictionary,
                    });

                    //Store in session
                    /*var sessionBeneficiaries = {CustomerID: this.props.objCustomer["Customer ID"],
                        CurrencyID: this.props.objDealHeader.SellCurrency,
                        Items: helper,
                    };*/
                    
                    //localStorage.setItem('DisbursementBeneficiaries', sessionBeneficiaries);
                }
            );
        }

        /*getActiveBeneficiariesBankAccountsByCustomerAndCurrency(this.props.objCustomer["Customer ID"], this.props.objDealHeader.SellCurrency).then(
            (json) => {
                var helper = [];
                var helperDictionary = {};
                if(json.accounts !== undefined){
                    var tbaRecord = {value:-2, name:'TBA', BeneficiaryID:-2};
                    helper.push(tbaRecord);
                    helperDictionary[-2] = tbaRecord;
                    for(var i=0; i<json.accounts.length; i++){
                        json.accounts[i].value = json.accounts[i].BankAccountID;
                        json.accounts[i].name = json.accounts[i].Description;
                        helper.push(json.accounts[i]);
                        helperDictionary[json.accounts[i].BankAccountID] = json.accounts[i];
                    }
                }
                this.setState({beneficiaryAccountList: helper,
                    beneficiaryAccountsDictionary: helperDictionary,
                });
            }
        );*/

        getUserDefinedPropertiesBySubEntityType('OutgoingFund', 0, 0).then((response) => {
            let properties = [];
            if (response.properties !== undefined) {
                response.properties.forEach((element) => {
                    if (element['ExtUserPropertyID$'] !== null) {
                        properties.push(element);
                    }
                });
                this.setState({ properties: properties });
            }
        });
        this.getListOfImportRecords();
        
    }

    getListOfImportRecords = () =>{
        getListOfPaymentOrderImportRecords(this.props.objDealHeader.CustomerID).then(
            (json) => {
              if (json != null && json.PaymentOrderImportRecord != null) {
                let list = json.PaymentOrderImportRecord.filter(c => c.Currency === this.props.objDealHeader.SellCurrency);
                this.setState({disbursementImportRecord: list});
              }
            }
          );
    }

    addDisbursement(outgoingFunds){
        //Calculate default amount
        var defaultAmount = parseFloat(this.props.objDealHeader.SellAmount);
        for(var i=0; i<outgoingFunds.length; i++){
            defaultAmount -= parseFloat(outgoingFunds[i].PaymentAmount);
        }
        defaultAmount = roundAmountByCurrency(defaultAmount, this.props.objDealHeader.SellCurrency);

        if(defaultAmount < 0){
            defaultAmount = 0;
        }

        var wireFeeSide = 'Buy';
        if(this.props.objCustomer['WireFeeSide$'] !== undefined){
            wireFeeSide = this.props.objCustomer['WireFeeSide$'];
        }
        var wireItemFeeCurrencyID = this.props.objDealHeader.BuyCurrency;
        if(wireFeeSide === 'Sell'){
            wireItemFeeCurrencyID = this.props.objDealHeader.SellCurrency;
        }
        let newID = outgoingFunds.length;
        outgoingFunds.unshift({'PaymentInstrument': '',
            'DeliveryMethod': '',
            'BeneficiaryBankAccountID': 0,
            'BeneficiaryID': 0,
            'BeneficiaryType': 0,
            'PaymentAmount': Number(defaultAmount),
            'WireItemFee': 0,
            'WireItemFeeCurrencyID': wireItemFeeCurrencyID,
            'PaymentReference': '',
            'ID':newID
        });

        return outgoingFunds;
    }

    calculateWireItemFee(outgoingFundList, index, instrument, amount){
        
        if(!['','select'].includes(instrument) &&
            amount !== 0){
                //alert(index + '-' + instrument + '-' + amount);
                calculateWireItemFee(this.props.objDealHeader.CustomerID,
                    this.props.objDealHeader.BuyCurrency,
                    this.props.objDealHeader.SellCurrency,
                    amount,
                    this.props.objDealHeader.CustomerRate,
                    instrument,
                    '').then(
                        (json) =>{
                            if(json !== undefined){
                                outgoingFundList[index].WireItemFee = Number(json.FeeAmount);
                                //this.setState({outgoingFundList: outgoingFundList});
                                this.setState({amountEnableUpdatesTrigger: false,
                                    outgoingFundList: outgoingFundList
                                }, () => {
                                    this.setState({amountEnableUpdatesTrigger: true});
                                });
                            }
                            else
                            {
                                this.setState({amountEnableUpdatesTrigger: false,
                                    outgoingFundList: outgoingFundList
                                }, () => {
                                    this.setState({amountEnableUpdatesTrigger: true});
                                });
                            }
                        }
                    );
            }
            else
                            {
                                this.setState({amountEnableUpdatesTrigger: false,
                                    outgoingFundList: outgoingFundList
                                }, () => {
                                    this.setState({amountEnableUpdatesTrigger: true});
                                });
                            }
    }

    checkDisbursementProperties = () => {
        let result = true;
        for (let i = 0; i < this.state.outgoingFundList.length; i++) {
            result = this.checkDisbursementProperty(this.state.outgoingFundList[i]);
        }
        return result;
    }

    checkDisbursementProperty = (outgoing) => {

        for (let i = 0; i < this.state.properties.length; i++) {
            const property = this.state.properties[i];
            if (property['Mandatory']) {
                if (outgoing.EntityProperties == null || outgoing.EntityProperties.length === 0) { // There is at least 1 mandatory property required but object doesn't have EntityProperties filled
                    return false;
                }
                const outgoingProperty = outgoing.EntityProperties.find((outgoingProperty => outgoingProperty.UserDefinedPropertyID === property['ExtUserPropertyID$']));

                if (outgoingProperty !== undefined) { // Found match.
                    switch(property['Data Type']) { // Check property type and validate depending on the type
                        default:
                        case 'Text': {
                            if (outgoingProperty.TextValue === '') {
                                return false;
                            }
                            break;
                        }
                        case 'Numeric': {
                            if (Number(outgoingProperty.NumValue) === 0) {
                                return false;
                            }
                            break;
                        }
                        case 'DateTime': {
                            if (outgoingProperty.DateValue === '' || outgoingProperty.DateValue === null) {
                                return false;
                            }
                            break;
                        }
                    }
                    
                } else {
                    return false; // Field was mandatory but property wasn't filled.
                }
            }
            else
            {
                
                if(property['ExtAmountLimit$'] > 0 && outgoing.PaymentAmountInBaseCCY > property['ExtAmountLimit$'])
                        {
                            if (outgoing.EntityProperties == null || outgoing.EntityProperties.length === 0) { // There is at least 1 mandatory property required but object doesn't have EntityProperties filled
                                return false;
                            }
                            const outgoingProperty = outgoing.EntityProperties.find((outgoingProperty => outgoingProperty.UserDefinedPropertyID === property['ExtUserPropertyID$']));
                
                            
                            if ((property['Data Type']== 'Text' && (outgoingProperty.TextValue == null || outgoingProperty.TextValue == ''))
                            || (property['Data Type'] == 'Numeric' && (outgoingProperty.NumValue == null || outgoingProperty.NumValue == '' || outgoingProperty.NumValue.value == 0))
                            || (property['Data Type'] == 'DateTime' && (outgoingProperty.DateValue === null || outgoingProperty.DateValue === '') ) )
                            {
                                return false;
                            }
                               
                            if (property['ExtAttachmentsRequired$']) { // Check Attachments
                                if (outgoingProperty.Attachments == null || outgoingProperty.Attachments.length == 0) { // No attachments but attachments are required
                                    return false;
                                }
                            }
                        }
            }
        }
        return true;
    }

    handleAddClick = (event) => {
        var outgoingFunds = this.state.outgoingFundList;
        outgoingFunds = this.addDisbursement(outgoingFunds);
        this.setState({amountEnableUpdatesTrigger: false,
            outgoingFundList: outgoingFunds
        }, () => {
            this.setState({amountEnableUpdatesTrigger: true});
        });
    }

    handleClearClick = (event) => {
        var newOutgoingFunds = [];
        var oldOutgoingFunds = this.state.outgoingFundList;
        oldOutgoingFunds.forEach(element => {
            if(Number(element.OutgoingFundsID) > 0){
                newOutgoingFunds.push(element);
            }
        });
        
        var outgoingFunds = this.addDisbursement(newOutgoingFunds);
        this.setState({amountEnableUpdatesTrigger: false, 
            outgoingFundList: outgoingFunds,
        }, () => {
            this.setState({amountEnableUpdatesTrigger: true});
        });
    }

    handleBackClick = (obj) => {
        this.props.onBackClicked(this.state.outgoingFundList);
    }

    handleSaveClick = (obj) => {
        //Validate amount
        var transactionIsValid = true;
        let list = this.state.outgoingFundList;
        var totalToDisburse = Number(0);
        for(var i=0; i<list.length; i++){
            totalToDisburse += Number(list[i].PaymentAmount);
        }

        let objDealHeader = this.props.objDealHeader;
        if(objDealHeader.DealType == 4 && objDealHeader.ForwardType == 'Closed')
        {
            if(Number(totalToDisburse) > Number(objDealHeader.SellAmount)){
                    transactionIsValid = false;
                    this.setState({showForwardAlert: true});
            }
        }

        if(objDealHeader.DealType === 2 ||
            objDealHeader.DealHeaderID > 0){
            if(Number(totalToDisburse) === Number(objDealHeader.SellAmount)){
                transactionIsValid = true;
            }
            else{
                this.setState({showNextAlert: true});
            }
        }
        else{
                transactionIsValid = true;            
        }

        if(transactionIsValid && this.validateFields()){
            if(Number(totalToDisburse) < Number(objDealHeader.SellAmount)){
                this.setState({showBalanceToDisburseAlert: true});
            }
            else{
                if (this.checkDisbursementProperties()) {
                    this.props.onNextStepClicked(this.state.outgoingFundList);
                } else {
                    this.setState({ showDisbursementPropertiesAlert: true });
                }
            }
        }
    }

    validateFields = () => {
        var result = true;
        var disbursements = this.state.outgoingFundList;
        for(var i=0; i<disbursements.length; i++){
            if(disbursements[i].PaymentInstrument === '' 
                || disbursements[i].PaymentInstrument === 'select'){
                    result = false;
                    break;
            }

            if(disbursements[i].BeneficiaryID === 0 ||
                disbursements[i].BeneficiaryID === undefined ||
                disbursements[i].BeneficiaryID === 'select'){
                result = false;
                break;
            }

            if(Number(disbursements[i].PaymentAmount) === 0){
                result = false;
                break;
            }

            //alert(result + '-' + disbursements[i].PaymentInstrument + '-' + disbursements[i].BeneficiaryType + '-' + disbursements[i].BeneficiaryBankAccountID)
            if(result 
                && disbursements[i].PaymentInstrument === 'Electronic Funds Transfer'
                && Number(disbursements[i].BeneficiaryBankAccountID) === 0
                && [0, 1].includes(Number(disbursements[i].BeneficiaryType))){
                result = false;
                this.showGlobalAlert('Warning', 'Electronic Funds Transfer payments requires a bank account to be selected.');
            }
        }

        return result;
    }

    handlePropertiesClick = (index) => {
        const entityProperties = this.state.outgoingFundList[index].EntityProperties;   
        let beneficiaryID = this.state.outgoingFundList[index].BeneficiaryID;

        const selectedDisbursementEntityProperties = entityProperties != null ? entityProperties : null;

        this.setState({ beneficiaryID: beneficiaryID, showDisbursementPropertiesDialog: true, disbursementDialogIndex: index, selectedDisbursementEntityProperties
        },
        ()=>
        getAmountInBaseCCy(this.props.objDealHeader.SellCurrency, this.state.outgoingFundList[index].PaymentAmount).then(
            (getamountInBaseCcy) => 
            {
                this.setState({selectDisbursementAmount: getamountInBaseCcy.response});
            }
        ));
    };
    
    closeDisbursementPropertiesDialog = (updateDisbursements = false, entityProperties, indexToUpdate) => {
        if (updateDisbursements) {
            let outgoingFunds = [...this.state.outgoingFundList];
            outgoingFunds[indexToUpdate].EntityProperties = entityProperties;
            outgoingFunds[indexToUpdate].EntityPropertiesJson = JSON.stringify(entityProperties);
            outgoingFunds[indexToUpdate].Status = 'Pending';
            outgoingFunds[indexToUpdate].Equivalent = roundAmountByCurrency( outgoingFunds[indexToUpdate].PaymentAmount * this.props.objDealHeader.BuyAmount / this.props.objDealHeader.SellAmount, this.props.objDealHeader.SellCurrency );
            outgoingFunds[indexToUpdate].UpdatedBy = localStorage.getItem('UserID');

            this.setState({ outgoingFundList: outgoingFunds, showDisbursementPropertiesDialog: false });
            
        } else {
            this.setState({ showDisbursementPropertiesDialog: false });
        }
    };

    handleDeleteClick(event){
        var outgoingTemp = this.state.outgoingFundList;
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        outgoingTemp.splice(index, 1);
        this.setState({amountEnableUpdatesTrigger: false,
            outgoingFundList: outgoingTemp,
        }, () => {
            this.setState({amountEnableUpdatesTrigger: true});
        });
    }

    showGlobalAlert = (title, message) =>{
        this.setState({showGlobalAlert: true,
            globalAlertTitle: title,
            globalAlertMessage: message,
        });
    }

    okNextAlert = () =>{
        this.setState({showNextAlert: false});
    }

    okForwardAlert = () =>{
        this.setState({showForwardAlert: false});
    }

    okGlobalAlert = () =>{
        this.setState({showGlobalAlert: false});
    }

    closeBalanceToDisburseAlert = () =>{
        this.setState({showBalanceToDisburseAlert: false});
    }

    yesBalanceToDisburseAlert = () =>{

        if (this.checkDisbursementProperties()) {
            this.props.onNextStepClicked(this.state.outgoingFundList);
        } else {
            this.setState({ showDisbursementPropertiesAlert: true });
        }
        //this.props.onNextStepClicked(this.state.outgoingFundList);
    }

    closeDisbursementsPropertiesAlert = () => {
        this.setState({ showDisbursementPropertiesAlert: false});
    };

    yesDisbursementsPropertiesAlert = () => {
        this.props.onNextStepClicked(this.state.outgoingFundList);
    };

    //#region handle updates
    /*handleUpdateOutgoingInstrument = (event) => {
        var outgoingTemp = this.state.outgoingFundList;
        let controlID = event.target.id;
        let index = controlID.substring(controlID.indexOf('-') + 1);
        outgoingTemp[index].PaymentInstrument = event.target.value;
        outgoingTemp[index].BeneficiaryID = -1;
        outgoingTemp[index].BeneficiaryBankAccountID = 0;
        outgoingTemp[index].EntityID = 0;
        this.calculateWireItemFee(outgoingTemp, index, event.target.value, outgoingTemp[index].PaymentAmount);
        this.setState({outgoingFundList: outgoingTemp});
    }*/
    handleUpdateOutgoingInstrument = (obj) => {
        if(obj.value !== undefined){
            //alert('handleUpdateOutgoingInstrument');
            //alert(JSON.stringify(obj));
            var outgoingTemp = this.state.outgoingFundList;
            let controlID = obj.controlID;
            let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp[index].PaymentInstrument = obj.value;
            //outgoingTemp[index].BeneficiaryID = -1;
            //outgoingTemp[index].BeneficiaryBankAccountID = 0;
            //outgoingTemp[index].EntityID = 0;
            outgoingTemp[index].DeliveryMethod = '';
            this.calculateWireItemFee(outgoingTemp, index, obj.value, outgoingTemp[index].PaymentAmount);
            //this.setState({outgoingFundList: outgoingTemp});
        }
    }

    handleUpdateOutgoingDeliveryMethod = (obj) => {
        if(obj.value !== undefined){
            //alert(JSON.stringify(obj));
            var value = obj.value;
            if(value === 'select'){
                value = '';
            }
            var outgoingTemp = this.state.outgoingFundList;
            let controlID = obj.controlID;
            let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp[index].DeliveryMethod = value;
            this.setState({outgoingFundList: outgoingTemp});
        }
    }

    handleUpdateOutgoingBeneficiary = (obj) => {
        if(obj.value !== undefined){

            if(obj.BeneficiaryID>0){                
                this.ValidateRequiredInvoiceDoc(obj.BeneficiaryID, this.state.beneficiaryAccountList)
                let obj1={
                    customerID: this.props.objDealHeader.CustomerID,
                    beneficiaryID: obj.BeneficiaryID
                }
                IsRestrictedCountry(obj1).then((json) => {
                    if(json.response){
                        this.setState({ showAlert1: true, alertTitle1: 'Warning', alertMessage1: 'No payments can be sent to this beneficiary while country is blocked.'});
                    }                    
                });
            }
            
            //alert(JSON.stringify(obj));
            if(obj.BeneficiaryStatus === 'Authorized'){
                var outgoingTemp = this.state.outgoingFundList;
                let controlID = obj.controlID;
                let index = controlID.substring(controlID.indexOf('-') + 1);
                let entityID = obj.value;
                outgoingTemp[index].EntityID = entityID;
                let ids = entityID.split('|');

                outgoingTemp[index].BeneficiaryBankAccountID = ids[1];
                outgoingTemp[index].BeneficiaryID = ids[0];
                outgoingTemp[index].CountryName = obj.CountryName;
                outgoingTemp[index].Name = obj.BeneficiaryName;

                if(obj.PreferredMethodOfPayment !== '' &&
                    obj.PreferredMethodOfPayment !== undefined){
                    let defaultInstrument = obj.PreferredMethodOfPayment
                    var instrumentsToValidate = this.state.outgoingInstrumentListForNonAccounts;
                    if(Number(ids[1]) > 0){
                        instrumentsToValidate = this.state.outgoingInstrumentList;
                    }
                    let instrumentInList = instrumentsToValidate.find(element => element.value === defaultInstrument);
                    if(instrumentInList !== undefined){
                        outgoingTemp[index].PaymentInstrument = defaultInstrument;    
                    }
                    else{
                        outgoingTemp[index].PaymentInstrument = '';
                    }
                }
                else{
                    outgoingTemp[index].PaymentInstrument = '';
                }

                if(obj.PreferredDeliveryMethod !== '' &&
                    obj.PreferredDeliveryMethod !== undefined){
                    outgoingTemp[index].DeliveryMethod = obj.PreferredDeliveryMethod;
                }
                else{
                    outgoingTemp[index].DeliveryMethod = '';
                }

                //Set default instrument funds on hold when TBA is selected
               /* if(Number(ids[0]) === -2){
                    outgoingTemp[index].PaymentInstrument = 'Hold fund in Client\'s account';
                    outgoingTemp[index].DeliveryMethod = '';
                }*/
                
                outgoingTemp[index].BeneficiaryType = 0;
                switch(Number(outgoingTemp[index].BeneficiaryID)){
                    case -1:
                        outgoingTemp[index].BeneficiaryType = 1;
                        /*outgoingTemp[index].BeneficiaryID = 0;
                        outgoingTemp[index].BeneficiaryBankAccountID = 0;*/
                        break;
                    case -2:
                        outgoingTemp[index].BeneficiaryType = 2;
                        /*outgoingTemp[index].BeneficiaryID = 0;
                        outgoingTemp[index].BeneficiaryBankAccountID = 0;*/
                        break;
                    default:
                        break;
                }

                if(entityID === 'select'){
                    outgoingTemp[index].BeneficiaryID = -1;
                    outgoingTemp[index].BeneficiaryBankAccountID = 0;
                }
                
                /*this.setState({ outgoingFundList: outgoingTemp }, () => {
                    this.calculateWireItemFee(outgoingTemp, index, outgoingTemp[index].PaymentInstrument, outgoingTemp[index].PaymentAmount);
                    this.setState({ outgoingFundList: outgoingTemp });
                });*/
                this.calculateWireItemFee(outgoingTemp, index, outgoingTemp[index].PaymentInstrument, outgoingTemp[index].PaymentAmount);
                    
            }
            else{
                this.setState({
                    globalAlertTitle: 'Error',
                    globalAlertMessage: 'Beneficiary is not Authorized to deal.',
                    showGlobalAlert: true,
                });
            }
        }
    }
    
    ValidateRequiredInvoiceDoc = (beneficiaryID, beneficiaries) => {
        if(beneficiaries !== undefined && beneficiaries !== null ){
            let bene = beneficiaries.find(b => b.BeneficiaryID == beneficiaryID);    
            if (bene) {
                let CountryRequiresDocs = false;
                if(bene.CountryRequiresDocs != null && bene.CountryRequiresDocs.toLowerCase() == 'yes'){
                    CountryRequiresDocs = true;
                }
                let BeneRequiresDocs = false;
                if(bene.BeneRequiresDocs != null && bene.BeneRequiresDocs.toLowerCase() == 'yes'){
                    BeneRequiresDocs = true;
                }
                
                let countryID=bene.CountryID;
                if(CountryRequiresDocs && BeneRequiresDocs){
                    let properties = this.state.properties;
                    let showMessage = false;
                    properties.map(p => {
                        if (p.Description.toLowerCase().includes('copy of invoice and number')) {
                            showMessage=true;
                        }
                        return true;
                      });
                    if(showMessage){
                        this.setState({
                            showAlert1:true,
                            alertTitle1:'Alert',
                            alertMessage1:"The beneficiary's country ("+ countryID +") requires additional documentation."})
                    }
                }
            }
        }        
      }

    handleUpdateOutgoingAmount = (event, index) => {
        if(this.state.amountEnableUpdatesTrigger){
            //alert('handleUpdateOutgoingAmount');
            var outgoingTemp = this.state.outgoingFundList;
            let controlID = event.target.id;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            let amount = Number(event.target.value);
            // outgoingTemp[index].PaymentAmount = roundAmountByCurrency(amount, this.props.objDealHeader.SellCurrency);
            outgoingTemp[index].PaymentAmount = amount;
            this.calculateWireItemFee(outgoingTemp, index, outgoingTemp[index].PaymentInstrument, event.target.value);
            //this.setState({outgoingFundList: outgoingTemp});
        }
    }

    handleOnBlurOutgoingAmount = async (event, index) => {
        let outgoingFunds = this.state.outgoingFundList;
        var pendingAmount = parseFloat(this.props.objDealHeader.SellAmount);
        
        for(var i=0; i<outgoingFunds.length; i++){
            pendingAmount -= parseFloat(outgoingFunds[i].PaymentAmount);
        }

        /*if(pendingAmount > 0){
            outgoingFunds = await this.addDisbursement(outgoingFunds);
        }*/

            this.getPendingAmountInBaseCCY();

        /*this.setState({outgoingFundList: outgoingFunds},
            
            );*/
    }

    getPendingAmountInBaseCCY = async () => 
        {
            let outgoingFunds = [...this.state.outgoingFundList];
            var outgoingFundListFinal = [];
            for(var d=0; d<outgoingFunds.length; d++){
                var helper = outgoingFunds[d];

                var amountBaseCCy = await getAmountInBaseCCy(this.props.objDealHeader.SellCurrency, outgoingFunds[d].PaymentAmount);
                helper.PaymentAmountInBaseCCY = amountBaseCCy.response;

                outgoingFundListFinal.push(helper);   
            }

            this.setState({outgoingFundList: outgoingFundListFinal});
        }

    handleUpdateOutgoingFee(event){
        var outgoingTemp = this.state.outgoingFundList;
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        outgoingTemp[index].WireItemFee = event.target.value;
        this.setState({outgoingFundList: outgoingTemp});
    }

    handleUpdateOutgoingYourReference(event){
        
        var outgoingTemp = this.state.outgoingFundList;
        let controlID = event.target.id;
        var index = controlID.substring(controlID.indexOf('-') + 1);
        outgoingTemp[index].PaymentReference = event.target.value;
        this.setState({outgoingFundList: outgoingTemp});
    }
    //#endregion

    closeAlert1(){
        this.setState({showAlert1: false});
    }

    handleDownloadClick = () =>
    {
        downloadTemplateCSV(this.state.disbursementImportRecord, 'ImportOutgoingsTemplate');
    }

    handleUploadClick = () =>{
        this.setState({showFileDialog: true});
    }

    onUploadFile = (fileName, fileContent) =>{
            var model = {
              'FileContent': fileContent,
              'UserID': sessionStorage.getItem('UserID'),
              'FileName': fileName,
              'CustomerID': this.props.objDealHeader.CustomerID
            }
            importPaymertOrdersTemplate(model).then((response) => {
              if (response != null) {
                let responseStatus = response.httpStatusCode
                var payments = (response.BeneficiaryPayments != null && response.BeneficiaryPayments.BeneficiaryPayment != null) ? response.BeneficiaryPayments.BeneficiaryPayment : [];
               
                if (responseStatus === 200) {
                    if (payments.length > 0) {
                      let CurrencyToWork = payments[0].CurrencyID;
                      
                      payments = payments.filter((i) => i.CurrencyID === CurrencyToWork);
                      let list = this.state.outgoingFundList;
                      if(list && list.length > 0)
                      {
                        list = list.filter(o => o.BeneficiaryID > 0 && o.BeneficiaryBankAccountID > 0 && o.PaymentInstrument !== '');
                      }
                      payments.forEach(async(payment, index) => {
                        /*
                          Amount: 1000
                          BeneficiaryBankAccountID: 1012328
                          BeneficiaryID: 13928
                          CurrencyID: "AUD"
                          Instrument: "Electronic Funds Transfer"
                          PaymentReference: "My reference"
                          ValueDate: "2021-09-07T00:00:00"
                          accountNo: "7564456456"
                          bankName: "Bank of Montreal "
                          beneficiaryName: "Sammy Hernandez"
                          beneficiaryType: "Personal"
                          id: "13928;1012328"
                          preferredDeliveryMethod: "We Drop-off"
                          status: "Authorized"
                          text: "Sammy Hernandez-Bank of Montreal "
                         */
                          let amount = Number(payment.Amount);
                          let amountBaseCCy = await getAmountInBaseCCy(this.props.objDealHeader.SellCurrency, amount);
                          let paymentAmountInBaseCCY = amountBaseCCy.response;
                          let wireItemFee= 0;
                          let bene = this.state.beneficiaryList.find(b => b.BeneficiaryID === payment.BeneficiaryID);
                          let countryName = '';

                          if(bene)
                          {
                            countryName = bene.CountryName;
                          }

                          if(!['','select'].includes(payment.Instrument) && amount !== 0){
                            //alert(index + '-' + instrument + '-' + amount);
                            let feeResponse = await calculateWireItemFee(this.props.objDealHeader.CustomerID, this.props.objDealHeader.BuyCurrency,
                                this.props.objDealHeader.SellCurrency, amount, this.props.objDealHeader.CustomerRate, payment.Instrument, '');
                            if(feeResponse && feeResponse.FeeAmount)
                            {
                                wireItemFee = Number(feeResponse.FeeAmount);
                            }
                        }
                          
                          list.push(
                          {
                            BeneficiaryBankAccountID: payment.BeneficiaryBankAccountID,
                            BeneficiaryID: payment.BeneficiaryID,
                            VersionNo: 0,
                            PaymentInstrument: payment.Instrument,
                            DeliveryMethod: payment.preferredDeliveryMethod,
                            ValueDate: payment.ValueDate,
                            PaymentAmount: amount,
                            DeliveryRefNo: "",
                            BeneRefNumber: "",
                            PaymentReference: payment.PaymentReference,
                            Status: "Pending",
                            WireItemFee: wireItemFee,
                            WireItemFeeCurrencyID: CurrencyToWork,
                            BeneficiaryType: payment.beneficiaryType,
                            entityProperties: [],
                            Name: payment.beneficiaryName,
                            PaymentAmountInBaseCCY: paymentAmountInBaseCCY,
                            CountryName: countryName
                          }                          
                        );

                        this.setState({outgoingFundList:list});
                        
                      });
                      
                    this.setState({globalAlertTitle:'Success', globalAlertMessage:'File Imported Successfully', showGlobalAlert: true, listErrors:[]}) ;
                    }
                    else
                    {
                        this.setState({globalAlertTitle:'Error', globalAlertMessage:'Invalid file', showGlobalAlert: true,listErrors:[]}) ;
                    }
                }
                else
                {
                    let listItemsError = [];
                    if(response.Message)
                    {
                        listItemsError = response.Message.split('#');

                        if(listItemsError && listItemsError.length > 0)
                        {
                            this.setState({globalAlertTitle:'Error', globalAlertMessage:'', showGlobalAlert: true,listErrors:listItemsError }) ;
                        }
                        else
                        {
                            this.setState({globalAlertTitle:'Error', globalAlertMessage:response.Message, showGlobalAlert: true, listErrors:[]}) ;
                        }
                    }
                    else
                    {
                        if(response.httpErrorMessage)
                        {
                        this.setState({globalAlertTitle:'Error', globalAlertMessage:response.httpErrorMessage, showGlobalAlert: true,listErrors:[]}) ;
                        }
                    }
                }
              }
              //}
            }).finally(() => {
              this.setState({showFileDialog: false});
            });
    }

    render() {
              
        //Build headers
        var wireFeeSide = 'Buy';
        if(this.props.objCustomer['WireFeeSide$'] !== null){
            wireFeeSide = this.props.objCustomer['WireFeeSide$'];
        }

        var feeCurrency = this.props.objDealHeader.SellCurrency;
        if(wireFeeSide === 'Buy'){
            feeCurrency = this.props.objDealHeader.BuyCurrency;
        }
        var headers = (
            <tr>
                <th key="1">Beneficiary</th>
                <th key="2">Instrument</th>
                <th key="3">Delivery Method</th>
                <th key="4">Amount ({this.props.objDealHeader.SellCurrency})</th>
                <th key="5">Equivalent ({this.props.objDealHeader.BuyCurrency})</th>
                <th key="6">Fee ({feeCurrency})</th>
                <th key="7">Payment Reference</th>
                <th key="8"></th>
            </tr>
        );
        
        let list = this.state.outgoingFundList;

        var totalAmount = 0;
        var totalEquivalent = 0;
        var totalFees = 0;

        //Instrument options
        //var instrumentOptions = [];
        //var initCount = 0;
        
        /*var placeholder = 'Select an instrument...';           
        for (var j=0; j<this.state.outgoingInstrumentList.length; j++) {
            instrumentOptions.push(<option key={this.state.outgoingInstrumentList[j].value} value={this.state.outgoingInstrumentList[j].value}>{this.state.outgoingInstrumentList[j].name}</option>);
        }*/
        //-----------------------

        //Beneficiary control options
        /*var beneficiaryControlOptions = [];
        initCount = 0;
        
        var beneficiaryPlaceholder = '';
        if(this.state.beneficiaryList.length > 0){
            beneficiaryPlaceholder = 'Select a Beneficiary...';
        }else{
            beneficiaryPlaceholder = 'Loading...';
        }
        initCount = 0;*/
        //-----------------------
        var rows = [];
        var allCompleted = true;
        for (let i=0; i<list.length; i++) {
            /*beneficiaryControlOptions = [];
            for (var k=initCount; k<this.state.beneficiaryAccountList.length; k++) {
                beneficiaryControlOptions.push(<option key={this.state.beneficiaryAccountList[k].value} value={this.state.beneficiaryAccountList[k].value}>{this.state.beneficiaryAccountList[k].name}</option>);
            }*/
            /*if(this.state.outgoingFundList[i].PaymentInstrument !== 'select' &&
                this.state.outgoingFundList[i].PaymentInstrument !== '' ){
                if(this.state.outgoingFundList[i].PaymentInstrument === 'Electronic Funds Transfer'){
                    //Load accounts
                    for (var k=initCount; k<this.state.beneficiaryAccountList.length; k++) {
                        beneficiaryControlOptions.push(<option key={this.state.beneficiaryAccountList[k].value} value={this.state.beneficiaryAccountList[k].value}>{this.state.beneficiaryAccountList[k].name}</option>);
                    }
                }
                else{
                    //Load beneficiaries
                    for (var l=initCount; l<this.state.beneficiaryList.length; l++) {
                        beneficiaryControlOptions.push(<option key={this.state.beneficiaryList[l].value} value={this.state.beneficiaryList[l].value}>{this.state.beneficiaryList[l].name}</option>);
                    }
                }
            }*/

            var feesDisabled = false;
            //It is not necessary for the client to have a FeeStructureID assigned, the trader can enter a value
            /*if(this.props.objCustomer['FeeStructureID$'] === null){
                feesDisabled = true;
            }*/

            var instrumentClassName = regularSelectClass;
            var beneficiaryClassName = regularSelectClass;
            var amountClassName = regularInputClass;
            if(['','select'].includes(this.state.outgoingFundList[i].PaymentInstrument)){
                instrumentClassName = notFilledSelectClass;  
            }
            if(this.state.outgoingFundList[i].BeneficiaryID === undefined ||
                this.state.outgoingFundList[i].BeneficiaryID === 'select' ||
                this.state.outgoingFundList[i].BeneficiaryID === 0){
                beneficiaryClassName = notFilledSelectClass;
            }
            if(Number(list[i].PaymentAmount) === 0){
                amountClassName = notFilledInputClass;
            }

            var equivalent = roundAmountByCurrency(list[i].PaymentAmount * this.props.objDealHeader.BuyAmount / this.props.objDealHeader.SellAmount, this.props.objDealHeader.BuyCurrency);

            var rowDisabled = false;
            let propertiesButton = (<i/>);
            var trashButton = (<i/>);
            var amountControl = (<NumberInput className={amountClassName} id={'amount-' + i} name={'amount-' + i} type="Currency" scale={getScaleByCurrency(this.props.objDealHeader.SellCurrency)} value={list[i].PaymentAmount} onChange={(e)=>{this.handleUpdateOutgoingAmount(e,i)}} onBlur={(e)=>{this.handleOnBlurOutgoingAmount(e,i)}} disabled={rowDisabled}/>);
            if(this.state.outgoingFundList[i].Status === 'Completed'){
                rowDisabled = feesDisabled = true;
                amountControl = (<NumberInput className={amountClassName} id={'amount-' + i} name={'amount-' + i} type="Currency" scale={getScaleByCurrency(this.props.objDealHeader.SellCurrency)} value={list[i].PaymentAmount} disabled={rowDisabled}/>);
            }

            propertiesButton = (
                <i
                    id={'properties-' + i}
                    onClick={() => this.handlePropertiesClick(i)}
                    className="fa fa-fw fa-info"
                    style={{ fontSize: '1.75em', verticalAlign: 'middle', color: this.checkDisbursementProperty(this.state.outgoingFundList[i]) ? 'inherit' : 'red' }}
                />
            );
            if(this.state.outgoingFundList[i].OutgoingFundsID === undefined){
                trashButton = (<i id={'delete-' + i} onClick={this.handleDeleteClick.bind(this)} className="fa fa-fw fa-trash" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}/>);
            }

            /*
            <select id={"instrument-" + i}
                            className={instrumentClassName}
                            onChange={this.handleUpdateOutgoingInstrument.bind(this)}
                            value={list[i].PaymentInstrument}
                            disabled={rowDisabled}>
                            <option value="select">{placeholder}</option>
                            {instrumentOptions}
                        </select>
                        */

            /*
            <select id={"beneficiary-" + i}
                            className={beneficiaryClassName}
                            onChange={this.handleUpdateOutgoingBeneficiary.bind(this)}
                            value={list[i].EntityID}
                            disabled={rowDisabled}>
                            <option value="select">{beneficiaryPlaceholder}</option>
                            {beneficiaryControlOptions}
                        </select>
             */
            //Get delivery methods
            var deliveryMethods = [];
            if(list[i].PaymentInstrument !== undefined &&
                list[i].PaymentInstrument !== '' &&
                list[i].PaymentInstrument !== 'select'){
                if(this.state.deliveryMethodList[list[i].PaymentInstrument] !== undefined){
                    deliveryMethods = this.state.deliveryMethodList[list[i].PaymentInstrument];
                }
            }
            
            var instruments = this.state.outgoingInstrumentListForNonAccounts;
            if(Number(list[i].BeneficiaryBankAccountID) > 0
                || Number(list[i].BeneficiaryType) === 2){
                instruments = this.state.outgoingInstrumentList;
            }

            //Instrument disabled
            var instrumentDisabled = false;
            //DeliberyMehtod disabled
            let deliberyMethodDisabled = false;
         /*   if(Number(list[i].BeneficiaryType) === 2){
                instrumentDisabled = true;
                deliberyMethodDisabled = true;
            }*/

            rows.push(<tr key={i}>
                    <td>
                        <DynamicSelect id={"beneficiary-" + i}
                            className={beneficiaryClassName}
                            placeholder="Select a Beneficiary..."
                            getValue={this.handleUpdateOutgoingBeneficiary}
                            value={list[i].BeneficiaryID + '|' + list[i].BeneficiaryBankAccountID}
                            objValue={{value:list[i].BeneficiaryID + '|' + list[i].BeneficiaryBankAccountID}}
                            disabled={rowDisabled}
                            data={this.state.beneficiaryAccountList}
                        />
                    </td>
                    <td>
                        <DynamicSelect id={"instrument-" + i}
                            className={instrumentClassName}
                            placeholder="Select an Instrument..."
                            getValue={this.handleUpdateOutgoingInstrument}
                            value={list[i].PaymentInstrument}
                            objValue={{value:list[i].PaymentInstrument}}
                            disabled={rowDisabled || instrumentDisabled}
                            data={instruments}
                        />
                    </td>
                    <td>
                        <DynamicSelect id={"deliveryMethod-" + i}
                            className="uk-select"
                            placeholder="Select a Method..."
                            getValue={this.handleUpdateOutgoingDeliveryMethod}
                            value={list[i].DeliveryMethod}
                            objValue={{value:list[i].DeliveryMethod}}
                            disabled={rowDisabled || deliberyMethodDisabled}
                            data={deliveryMethods}
                        />
                    </td>
                    <td>{amountControl}</td>
                    <td align="right">{formatToCurrencyWithScale(equivalent, this.props.objDealHeader.BuyCurrency)}</td>
                    <td><NumberInput id={'fee-' + i} type="Currency" value={list[i].WireItemFee} scale={getScaleByCurrency(list[i].WireItemFeeCurrencyID)} onChange={this.handleUpdateOutgoingFee.bind(this)} disabled={feesDisabled} className="uk-input"/></td>
                    <td><input id={'yourReference-' + i} type="text" value={list[i].PaymentReference} onChange={this.handleUpdateOutgoingYourReference.bind(this)} className="uk-input" disabled={rowDisabled}/></td>
                    <td><div className="uk-button-group">{propertiesButton}{trashButton}</div></td>
                </tr>);
            totalAmount += Number(list[i].PaymentAmount);
            totalEquivalent += Number(equivalent);
            totalFees += Number(list[i].WireItemFee);

            if(list[i].Status != 'Completed')
            {
                allCompleted = false;
            }
        }

        totalAmount = roundAmountByCurrency(totalAmount, this.props.objDealHeader.SellCurrency);
        if(Number(this.props.objDealHeader.SellAmount) === Number(totalAmount)){
            totalEquivalent = this.props.objDealHeader.BuyAmount;
        }
        else{
            totalEquivalent = roundAmountByCurrency(totalEquivalent, this.props.objDealHeader.BuyCurrency);
        }
        
        totalFees = roundAmountByCurrency(totalFees, this.props.objDealHeader.BuyCurrency);

        rows.push(<tr key="totals">
                <td></td>
                <td></td>
                <td><b>Total</b></td>
                <td align="right">{formatToCurrencyWithScale(totalAmount, this.props.objDealHeader.SellCurrency)}</td>
                <td align="right">{formatToCurrencyWithScale(totalEquivalent, this.props.objDealHeader.BuyCurrency)}</td>
                <td align="right">{formatToCurrencyWithScale(totalFees, this.props.objDealHeader.BuyCurrency)}</td>
                <td></td>
                <td></td>
            </tr>);

        let objDealHeader = this.props.objDealHeader;

        //Deal summary
        var dealSummary;
        
        var dealIDLabel;
        if (objDealHeader.DealHeaderID !== undefined &&
            objDealHeader.DealHeaderID !== 0) {
            dealIDLabel = (<div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {objDealHeader.DealHeaderID}</h3></div>);
            dealSummary = (//<div className="input-number" >
                <WizardDealSummary objDealHeader={objDealHeader} disbursements = {this.state.outgoingFundList} loadDeal={this.props.loadDeal}/>);
        }

        //Overdisbursed message
        var overDisbursedMessage = '';
        if(totalAmount > Number(objDealHeader.SellAmount)){
            overDisbursedMessage = <span className="warning-style">{'(Note: you are overdisbursing this deal)'}</span>;
        }
        var buttons = [];
        buttons.push(<button className="uk-button uk-button-green" onClick={this.handleAddClick}>Add</button>)
        buttons.push(<button className="uk-button uk-button-green" onClick={this.handleClearClick}>Clear Fields</button>);
        
        buttons.push(<button className="uk-button uk-button-green" onClick={this.handleDownloadClick}>Download Template</button>);
        buttons.push(<button className="uk-button uk-button-green" onClick={this.handleUploadClick}>Upload Template</button>);

        if(Number(objDealHeader.SellAmount) - totalAmount == 0 && allCompleted)
        {
            buttons= '';
        }

        return (
            <div>
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-2-5">
                            <h3>Customer: {this.props.objCustomer["Customer Name"]}</h3>
                            <h3>Contact: {this.props.objContact.Name}</h3>
                        </div>
                        <div className="uk-width-3-5" style={{ display: 'inline-flex', justifyContent: 'flex-end'  }}>
                            {/*dealIDLabel*/}
                            {dealSummary}
                        </div>
                    </div>
                </div>
                
                <hr className="uk-hr"/>

                {buttons}
                
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-2-5" align="right"><b>Sell Amount: {formatToCurrencyWithScale(objDealHeader.SellAmount, objDealHeader.SellCurrency) + ' ' + objDealHeader.SellCurrency}</b></div>
                        <div className="uk-width-3-5"><b>Pending balance: {formatToCurrencyWithScale(Number(objDealHeader.SellAmount) - totalAmount, objDealHeader.SellCurrency)}&nbsp;{overDisbursedMessage}</b></div>
                    </div>
                </div>             

                <table className="uk-table uk-table-hover uk-table-divider border-table">
                    <thead>
                        {headers}
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>

                <hr className="uk-hr"/>

                <div align="right">
                    <button className="uk-button uk-button-green" onClick={this.handleBackClick}>Back</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSaveClick}>Next</button>
                </div>
                <AlertBox id="global-alert"
                    open={this.state.showGlobalAlert}
                    title={this.state.globalAlertTitle}
                    message={this.state.globalAlertMessage}
                    type="Ok"
                    messageBoldList = {this.state.listErrors}
                    okClick={this.okGlobalAlert}/>
                
                <AlertBox id="next-alert" 
                    open={this.state.showNextAlert} 
                    title="Error" 
                    message={"You need to disburse: " + formatToCurrencyWithScale(this.props.objDealHeader.SellAmount, this.props.objDealHeader.SellCurrency) + "!"}
                    type="Ok"
                    okClick={this.okNextAlert}/>
                <AlertBox id="forward-alert" 
                    open={this.state.showForwardAlert} 
                    title="Error" 
                    message={"Cannot overdisburse a closed forward."}
                    type="Ok"
                    okClick={this.okForwardAlert}/>

                <AlertBox open={this.state.showBalanceToDisburseAlert} 
                    onClose={this.closeBalanceToDisburseAlert} 
                    title="Warning"
                    message={"There is a still a balance of " + formatToCurrencyWithScale(Number(objDealHeader.SellAmount) - totalAmount, this.props.objDealHeader.SellCurrency) + " to be disbursed. Do you want to continue?"}
                    type="Yes/No"
                    yesClick={this.yesBalanceToDisburseAlert} 
                    noClick={this.closeBalanceToDisburseAlert}/>
                <AlertBox open={this.state.showDisbursementPropertiesAlert}
                    onClose={this.closeDisbursementsPropertiesAlert}
                    title="Warning"
                    message="There are properties that haven't been filled. Do you want to continue?"
                    type="Yes/No"
                    yesClick={this.yesDisbursementsPropertiesAlert}
                    noClick={this.closeDisbursementsPropertiesAlert}/>
                <DisbursmentPropertiesDialog
                    open={this.state.showDisbursementPropertiesDialog}
                    onClose={this.closeDisbursementPropertiesDialog}
                    index={this.state.disbursementDialogIndex}
                    entityProperties={this.state.selectedDisbursementEntityProperties}
                    disabled={rowDisabled}
                    amount = {this.state.selectDisbursementAmount}
                    beneficiaryID = {this.state.beneficiaryID}
                    beneficiaryAccountList = {this.state.beneficiaryAccountList}
                />
                <AlertBox open={this.state.showAlert1} onClose={this.closeAlert1.bind(this)} title={this.state.alertTitle1} message={this.state.alertMessage1} type="Ok" okClick={this.closeAlert1.bind(this)}/>
                <GeneralDragAndDropFileDialog
                                    open            = {this.state.showFileDialog}
                                    onUploadFile    = {this.onUploadFile}
                                    onClose         = {() =>{ this.setState({showFileDialog: false})}}
                                    validExtensions = 'xlsx,csv'
                                />
            </div>);
    }
}