import React from 'react';
import {genericGetWithParameters} from '../../../networking/Networking'
import {getDrawdownsByForward} from '../../../networking/NetworkingDeals'
import {getQuotesByStatus} from '../../../networking/NetworkingQuotes'
import {getRateQuotesByCustomer} from '../../../networking/NetworkingRates'
import NewDynamicTable from '../../shared/NewDynamicTable';
import Paginator from '../../shared/Paginator';
import {formatToCurrencyWithScale, formatDate, formatDateString, getScaleByCurrency, formatToCurrency} from '../../../helpers/FormatHelper';
import NumberInput from '../../shared/NumberInput';
import DatePicker from '../../shared/DatePicker';
import {getStyle} from '../../../styles/styles'
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
import { Breadcrumb } from 'antd';

export default class WizardTransactionsView extends React.Component {
    
    state = {
        latestDeals:[],        
        drawdowns_tag:[],
        latestDealsCurrentPage: 1,
        latestDealsPages: 0,
        latestDealsPagesNewSearch: false,
        onlineQuotes: [],
        onlineQuotesCurrentPage: 1,
        onlineQuotesNewSearch: false,
        onlineQuotesPages: 0,
        outstandingForwards:[],
        outstandingForwardsPages: 0,
        outstandingForwardsNewSearch: false,
        quotes: [],
        quotesCurrentPage: 1,
        quotesNewSearch: false,
        quotesPages: 0,        
        drawdowns: [],
        dealHeaderIDFrom: 0,
        dealHeaderIDTo: 0,
        dealType: '',
        sellAmountFrom: 0,
        sellAmountTo: 0,
        sellCurrency: '',
        buyAmountFrom: 0,
        buyAmountTo: 0,
        buyCurrency: '',
        valueDateFrom: '',
        valueDateTo: '',
        dealHeaderID: 0,
        status: '',
        currentView: 'regular',
        forwardID: 0,
        originalAmounts: true,
        enableClick: true,
        comments:{
            DealHeaderID: 0,
            bankReference: '',
            internalComments: '',
            clientComments: '',
            UpdateToken: ''
        },
        viewComments: false,
        totalDDBought:'0',
        totalBBSold:'0',
        buyCurrencyDD:'',
        sellCurrencyDD:''
    }

    componentDidMount(){
        if(this.props.enableClick != undefined)
        {
            this.setState({enableClick: this.props.enableClick});
        }
        this.loadDeals();
        this.searchQuotes(1, true);
        this.searchOnlineQuotes(1, true);
        if(this.props.objDealHeader != undefined)
        {
            if(this.props.objDealHeader.DealHeaderID > 0)
            {
                this.loadDrawdowns_tag();
                var bankReference= '', internalComments= '', clientComments= '';
                if(this.props.objDealHeader.CustomerRefNo)
                {
                    bankReference = this.props.objDealHeader.CustomerRefNo;
                }
                if(this.props.objDealHeader.InternalComments)
                {
                    internalComments = this.props.objDealHeader.InternalComments;
                }
                if(this.props.objDealHeader.ExternalComments)
                {
                    clientComments = this.props.objDealHeader.ExternalComments;
                }

                this.setState({
                    viewComments: true, comments: {
                        ...this.state.comments, DealHeaderID: this.props.objDealHeader.DealHeaderID
                        , bankReference: bankReference, internalComments: internalComments, clientComments: clientComments
                        , UpdateToken: this.props.objDealHeader.UpdateToken
                    }, objDealHeader: this.props.objDealHeader
                })
            }
        }
    }

    componentDidUpdate(prevProps){
        if(Number(prevProps.quotesRefreshFlag) !== Number(this.props.quotesRefreshFlag)){
            this.searchQuotes(this.state.quotesCurrentPage, false);
        }

        if(Number(prevProps.dealsRefreshFlag) !== Number(this.props.dealsRefreshFlag)){
            this.loadDeals();
            this.searchLatestDeals(this.state.latestDealsCurrentPage, false);
        }
        
        if(prevProps.enableClick != this.props.enableClick)
        {
            this.setState({enableClick: this.props.enableClick});
        }

        if(prevProps.objDealHeader != this.props.objDealHeader)
        {
            if(this.props.objDealHeader.DealHeaderID > 0)
            {
                this.loadDrawdowns_tag();
                var bankReference= '', internalComments= '', clientComments= '';
                if(this.props.objDealHeader.CustomerRefNo)
                {
                    bankReference = this.props.objDealHeader.CustomerRefNo;
                }
                if(this.props.objDealHeader.InternalComments)
                {
                    internalComments = this.props.objDealHeader.InternalComments;
                }
                if(this.props.objDealHeader.ExternalComments)
                {
                    clientComments = this.props.objDealHeader.ExternalComments;
                }

                this.setState({viewComments: true, comments: {...this.state.comments, DealHeaderID: this.props.objDealHeader.DealHeaderID
                    , bankReference: bankReference, internalComments: internalComments, clientComments: clientComments
                    , UpdateToken: this.props.objDealHeader.UpdateToken}})
            }
            else
            {
                this.setState({viewComments: false, comments: {...this.state.comments, DealHeaderID: 0, bankReference: '', internalComments: '', clientComments: '', UpdateToken:''}});
            }
        }
    }

    loadDrawdowns_tag(){
        if(this.props.objDealHeader.DealHeaderID > 0)
            {
                let params2 = {
                    'dealHeaderID': this.props.objDealHeader.DealHeaderID
                }
                genericGetWithParameters('Deals/FordwardGetDrawDowns', params2).then(
                    (value) => {
                        var temp = [];
                        let buyCC = '';
                        let buyAmountDD = 0;
                        let sellCC = '';
                        let sellAmountDD = 0;

                        if(value.deals !== undefined){
                            temp = value.deals.map((element) => ({
                                'Deal ID': element['Deal Header ID'],
                                ...element
                            }));
                            value.deals.map((element) => {
                                buyCC = element['Buy Currency'];
                                sellCC = element['Sell Currency'];
                                buyAmountDD += Number(element['Buy Amount'].toString().replace(',',''));
                                sellAmountDD += Number(element['Sell Amount'].toString().replace(',',''));
                            });
                        }
                        let formatTotalBougth = '0';
                        let formatTotalSold = '0';
                        if(buyAmountDD > 0)
                        {
                            formatTotalBougth = formatToCurrencyWithScale(buyAmountDD, buyCC);
                        }
                        if(sellAmountDD > 0)
                        {
                            formatTotalSold = formatToCurrencyWithScale(sellAmountDD, sellCC);
                        }
                        
                        this.setState({drawdowns_tag: temp, buyCurrencyDD: buyCC, totalDDBought: formatTotalBougth, sellCurrencyDD: sellCC, totalBBSold: formatTotalSold});
                    }
                );
            }
    }

    loadDeals(){
        //Load latest deals
        let params = {
            'customerID': this.props.objCustomer["Customer ID"],
            'dealHeaderIDFrom': 0, 
            'dealHeaderIDTo': 0,
            'dealType': '', 
            'sellAmountFrom': 0, 
            'sellAmountTo': 0, 
            'sellCurrency': '', 
            'buyAmountFrom': 0, 
            'buyAmountTo': 0, 
            'buyCurrency': '',
            'valueDateFrom': null,
            'valueDateTo': null,
            'page': 1,
            'items': 10,
        }
        genericGetWithParameters('Deals/GetLatest10Deals', params).then(
            (value) => {
                var temp = [];
                if(value.deals !== undefined){
                    temp = value.deals.map((element) => ({
                        'Deal ID': element['Deal Header ID'],
                        ...element,
                        'Far Date': element['Value Date']
                    }));
                }
                this.setState({latestDeals: temp});
            }
        );           

        //Load outstanding forwards
        this.searchForwards(1, true);
    }

    /*refreshQuotes(){
        this.searchQuotes(1, true);
    }*/

    changeLatestDealsPage = (value) => {
        this.setState({latestDealsCurrentPage: value});
        this.searchLatestDeals(value, false);
    }

    changeOutstandingForwardsPage = (value) => {
        this.searchForwards(value, false);
    }

    changeQuotesPage = (value) => {
        this.setState({quotesCurrentPage: value});
        this.searchQuotes(value, false);
    }

    changeOnlineQuotesPage = (value) => {
        this.setState({onlineQuotesCurrentPage: value});
        this.searchOnlineQuotes(value, false);
    }

    searchLatestDeals(page, newSearch){
        let itemsPerPage = getDefaultPagingNumberOfItems();
        let params = {
            'customerID': this.props.objCustomer["Customer ID"],
            'dealHeaderIDFrom': this.state.dealHeaderIDFrom, 
            'dealHeaderIDTo': this.state.dealHeaderIDTo,
            'dealType': this.state.dealType, 
            'sellAmountFrom': this.state.sellAmountFrom, 
            'sellAmountTo': this.state.sellAmountTo, 
            'sellCurrency': this.state.sellCurrency, 
            'buyAmountFrom': this.state.buyAmountFrom, 
            'buyAmountTo': this.state.buyAmountTo, 
            'buyCurrency': this.state.buyCurrency, 
            'page': page,
            'items': itemsPerPage
        };
        
        if(this.state.valueDateFrom !== ''){
            params.valueDateFrom = formatDate(this.state.valueDateFrom);
        }
        else{
            params.valueDateFrom = null;
        }
        if(this.state.valueDateTo !== ''){
            params.valueDateTo = formatDate(this.state.valueDateTo);
        }
        else{
            params.valueDateTo = null;
        }
        
        genericGetWithParameters('Deals/GetLatest10Deals', params).then(
            (value) => {
                var temp = [];
                var totalCount = 0;
                var pages = 1;
                if(value.deals !== undefined && value.totalCount !== undefined){
                    totalCount = value.totalCount;
                    pages = Math.ceil(totalCount/itemsPerPage);
                    temp = value.deals.map((element) => ({
                        'Deal ID': element['Deal Header ID'],
                        ...element,
                        'Far Date': element['Value Date']
                    }));
                }
                this.setState({latestDeals: temp,
                    latestDealsPages: pages,
                    latestDealsPagesNewSearch: newSearch,
                });
            }
        );
    }

    searchForwards(page, newSearch){
        let itemsPerPage = getDefaultPagingNumberOfItems();
        let params = {
            'CustomerID': this.props.objCustomer["Customer ID"],
            'Page': page,
            'Items': itemsPerPage
        }
        genericGetWithParameters('Deals/GetOutstandingForwardsByCustomer', params).then(
            (value) => {
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                console.group('Wizard Trade Screen - GetOutstandingForwardsByCustomer');
                console.table(value);
                console.groupEnd();
                var helper = [];
                if (totalCount > 0) {
                    helper = value.deals.map((element) => ({
                        'Deal ID': element['Deal Header ID'],
                        ...element,
                        'Far Date': element['Value Date'],
                        'Type': element['Forward Type'],
                    }));
                }
                this.setState({outstandingForwards: helper,
                    outstandingForwardsPages: pages,
                    outstandingForwardsNewSearch: newSearch});
            }
        );
    }

    searchQuotes(page, newSearch){
        let itemsPerPage = getDefaultPagingNumberOfItems();
        getQuotesByStatus(this.props.objCustomer["Customer ID"], 'Active', page, itemsPerPage).then(
            (value) => {
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                var helper = [];
                if(totalCount >0){
                   helper = value.quotes;
                }
                this.setState({quotes: helper,
                    quotesPages: pages,
                    quotesNewSearch: newSearch});
            }
        );
    }

    searchOnlineQuotes(page, newSearch){
        let itemsPerPage = getDefaultPagingNumberOfItems();
        getRateQuotesByCustomer(this.props.objCustomer["Customer ID"], true, page, itemsPerPage).then(
            (value) => {
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                var helper = [];
                if(totalCount >0){
                   helper = value.quotes;
                }
                this.setState({onlineQuotes: helper,
                    onlineQuotesPages: pages,
                    onlineQuotesNewSearch: newSearch});
            }
        );
    }

    onViewMoreClick = (event) => {
        this.searchLatestDeals(1, true);
        this.setState({currentView: 'viewMore'});
    }

    handleSearchClick = (event) => {
        this.searchLatestDeals(1, true);
    }

    handleCloseSearchClick = (event) => {
        this.loadDeals();
        this.setState({currentView: 'regular'});
    }

    loadDrawdowns = (forwardID) =>{
        getDrawdownsByForward(forwardID, 0, 0).then(
            (json) => {
                var helper = [];
                if(json.deals !== undefined){
                    helper = json.deals;
                }
                this.setState({drawdowns:helper});
            }
        )
        this.setState({currentView: 'drawdowns'});
    }

    handleCloseDrawdownsClick = (event) => {
        this.loadDeals();
        this.setState({currentView: 'regular'});
    }

    handleUpdateColumnsType = (originalAmounts) => {
        this.setState({originalAmounts: originalAmounts});
    }

    //#region Handle Updates

    handleUpdateDealFrom = (event) => {
        this.setState({dealHeaderIDFrom: event.target.value});
    }

    handleUpdateDealTo = (event) => {
        this.setState({dealHeaderIDTo: event.target.value});
    }

    handleUpdateDealType = (event) => {
        this.setState({dealType: event.target.value});
    }

    handleUpdateSellAmountFrom = (event) => {
        this.setState({sellAmountFrom: event.target.value});
    }

    handleUpdateSellAmountTo = (event) => {
        this.setState({sellAmountTo: event.target.value});
    }

    handleUpdateSellCurrency = (event) => {
        this.setState({sellCurrency: event.target.value});
    }

    handleUpdateBuyAmountFrom = (event) => {
        this.setState({buyAmountFrom: event.target.value});
    }

    handleUpdateBuyAmountTo = (event) => {
        this.setState({buyAmountTo: event.target.value});
    }

    handleUpdateBuyCurrency = (event) => {
        this.setState({buyCurrency: event.target.value});
    }

    handleUpdateValueDateFrom = (date) => {
        this.setState({valueDateFrom: date});
    }

    handleUpdateValueDateTo = (date) => {
        this.setState({valueDateTo: date});
    }

    handleUpdateBankReference = (event) => {
        this.setState({comments:{...this.state.comments, bankReference: event.target.value }});
    }

    handleUpdateInternalComments = (event) => {
        this.setState({comments:{...this.state.comments, internalComments: event.target.value }});
    }
    handleUpdateClientComments = (event) => {
        this.setState({comments:{...this.state.comments, clientComments: event.target.value }});
    }

    handleSaveComments = () => {
        if(this.props.handleSaveComments)
        {
            this.props.handleSaveComments(this.state.comments, true);
        }
    }

    loadDrawdown = (objDD) =>{
        if(this.props.loadDeal && objDD && objDD["Deal ID"])
            {
                let dealObj = {DealNo: objDD["Deal ID"]};
                this.props.loadDeal(dealObj, true);
            }
    }
    //#endregion

    /*handleActiveTab = (tab) => {
        return activeTab === tab ? 'uk-active' : '';
    }*/

    render(){
        //Forward hidden headers
        var forwardHiddenHeaders = ['Buy Amount', 'Sell Amount','Deal Header ID','Value Date','Forward Type'];
        if(this.state.originalAmounts){
            forwardHiddenHeaders = ['Buy Pending Balance', 'Sell Pending Balance','Deal Header ID','Value Date','Forward Type'];
        }

        var existComment = '';
        if((this.state.comments.bankReference != null && this.state.comments.bankReference != '' )
        || (this.state.comments.internalComments != null && this.state.comments.internalComments != '' )
        || (this.state.comments.clientComments != null && this.state.comments.clientComments != '' ))
        {
            existComment = '(*)';
        }

        var forwardIDLabel;
        if (this.props.objDealHeader.ForDrawdownCreation ||
            (this.props.objDealHeader.DealHeaderID === 0 &&
                this.props.objDealHeader.ParentDealHeaderID > 0)) {
            forwardIDLabel = (<h4 className="uk-heading-divider" uk-toggle="target : #forwardsSection;">Creating drawdown for forward {this.props.objDealHeader.ParentDealHeaderID}</h4>);
        }

        var dealListsView;
        switch(this.state.currentView){
            case 'regular':
            default:
                dealListsView = (
                    <div>
                        <div>
                            {forwardIDLabel}
                        </div>
                        <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #forwardsSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>Outstanding forwards &nbsp; (Select a forward to draw down) <i id="forwardsSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="forwardsSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                            </h5>
                            <div id="forwardsSection" hidden>
                                <div className='uk-margin-bottom'>
                                    <label><input value="OriginalAmounts" className="uk-radio" type="radio" name="columnsType" onChange={() => this.handleUpdateColumnsType(true)} checked={this.state.originalAmounts}/> Original Amounts</label>&nbsp;
                                    <label><input value="Pending Balance" className="uk-radio" type="radio" name="columnsType" onChange={() => this.handleUpdateColumnsType(false)} checked={!this.state.originalAmounts}/> Pending Balance</label>
                                </div>

                                <NewDynamicTable data={this.state.outstandingForwards} 
                                        enableClick={false} 
                                        clickFunction={this.props.handleForwardClick}
                                        newSearch={this.state.outstandingForwardsNewSearch}
                                        useDeleteButton={false}
                                        hiddenHeaders={forwardHiddenHeaders}
                                        dynamicActions={[
                                            { name: 'Drawdown', function: this.props.handleForwardClick, isIcon: true, icon:'fa fa-paper-plane-o'},
                                            { name: 'View', function: this.props.handleDealClick, isIcon: true, icon:'fa fa-search'},
                                            ]} />
                                        
                                <Paginator changePage={this.changeOutstandingForwardsPage} 
                                    numberOfPages={this.state.outstandingForwardsPages} 
                                    newSearch={this.state.outstandingForwardsNewSearch}/>
                            </div>
                        </div>

                        <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #DrawDownsSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>
                                    {'Draw Downs'}<i id="DrawDownsSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="DrawDownsSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                                    {` (Bought: ${this.state.totalDDBought} ${this.state.buyCurrencyDD} / Sold: ${this.state.totalBBSold} ${this.state.sellCurrencyDD})`}            
                            </h5>
                            <div id="DrawDownsSection" hidden>
                                <NewDynamicTable data={this.state.drawdowns_tag} 
                                    enableClick={false}
                                    useDeleteButton={false}
                                    hiddenHeaders={['Deal Header ID']}
                                    clickFunctionHeader={'Deal ID'}
                                    clickFunction={this.loadDrawdown} />
                            </div>
                        </div>


                        <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #dealsSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>Latest 10 deals<i id="dealsSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="dealsSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                            </h5>
                            <div id="dealsSection" hidden>
                                <a onClick={this.onViewMoreClick}>View more...</a>
                                <NewDynamicTable data={this.state.latestDeals} 
                                    enableClick={this.state.enableClick}
                                    clickFunction={this.props.handleDealClick}
                                    useDeleteButton={false}
                                    newSearch={true}
                                    /*formats={[{header:'Buy Amount', formatFunction: formatToCurrency},
                                        { header: 'Sell Amount', formatFunction: formatToCurrency }]}*/
                                    hiddenHeaders={['Deal Header ID', 'Value Date']} />
                            </div>
                        </div>

                        <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #quotesSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>Quotes<i id="quotesSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="quotesSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                            </h5>
                            <div id="quotesSection" hidden>
                                <NewDynamicTable data={this.state.quotes}
                                    enableClick={this.state.enableClick}
                                    clickFunction={this.props.handleQuoteClick}
                                    useDeleteButton={false}
                                    newSearch={this.state.quotesNewSearch}
                                    /*formats={[{header:'Buy Amount', formatFunction: formatToCurrency},
                                        {header:'Sell Amount', formatFunction:formatToCurrency}]}*/
                                    />
                                <Paginator changePage={this.changeQuotesPage} 
                                    numberOfPages={this.state.quotesPages} 
                                    newSearch={this.state.quotesNewSearch}/>
                            </div>
                        </div>

                        <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #onlineQuotesSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>Online Quotes<i id="onlineQuotesSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="onlineQuotesSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                            </h5>
                            <div id="onlineQuotesSection" hidden>
                                <NewDynamicTable data={this.state.onlineQuotes}
                                    enableClick={false}
                                    useDeleteButton={false}
                                    newSearch={this.state.onlineQuotesNewSearch}
                                    /*formats={[{header:'Buy Amount', formatFunction: formatToCurrency},
                                        {header:'Sell Amount', formatFunction:formatToCurrency}]}*/
                                    />
                                <Paginator changePage={this.changeOnlineQuotesPage} 
                                    numberOfPages={this.state.onlineQuotesPages} 
                                    newSearch={this.state.onlineQuotesNewSearch}/>
                            </div>
                        </div>

                        {/* Comments*/}
                        {this.state.viewComments && <div>
                            <h5 className="uk-heading-divider" 
                                uk-toggle="target : #commentsSection;"
                                style={{...getStyle().titleStyle, cursor: 'pointer'}}>{"Comments "+existComment}<i id="commentsSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="commentsSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                            </h5>
                            <div uk-grid="true" id="commentsSection" hidden>
                                <div className="uk-width-1-1 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-attachment-description">Customer/Bank Reference</label>
                                    <div className="">
                                        <input
                                            className="uk-input"
                                            id="form-attachment-bankReference"
                                            type="text"
                                            placeholder="Bank Reference"
                                            value={this.state.comments.bankReference}
                                            onChange={this.handleUpdateBankReference} 
                                            disabled={!this.state.enableClick} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-attachment-description">Internal Comments</label>
                                    <div className="">
                                        <textarea className="uk-textarea"
                                            id="form-attachment-internalComments"
                                            type="textarea"
                                            placeholder="Internal Comments"
                                            value={this.state.comments.internalComments}
                                            onChange={this.handleUpdateInternalComments} 
                                            disabled={!this.state.enableClick} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-attachment-description">Client Comments</label>
                                    <div className="">
                                        <textarea className="uk-textarea"
                                            id="form-attachment-internalComments"
                                            type="textarea"
                                            placeholder="Client Comments"
                                            value={this.state.comments.clientComments}
                                            onChange={this.handleUpdateClientComments}
                                            disabled={!this.state.enableClick} />
                                    </div>
                                </div>
                                <div>
                                    {this.state.enableClick && <button className="uk-button uk-button-green" onClick={this.handleSaveComments}>Save</button>}
                                </div>
                            </div>
                        </div>}
                    </div>
                    
                );
                break;
            case 'viewMore':
                dealListsView = (
                    <div className="uk-form-stacked">
                        <h4>
                            <div className="breadcrumb-div">
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item className='breadcrumb-item' onClick={this.handleCloseSearchClick}>Latest 10 deals</Breadcrumb.Item>
                                    <Breadcrumb.Item className="breadcrumb-item-bold">view more</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </h4>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-deal-from">Deal from</label>
                                    <NumberInput className="uk-input" id="form-deal-from" type="Integer" value={this.state.dealHeaderIDFrom} onChange={this.handleUpdateDealFrom}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-deal-to">Deal to</label>
                                    <NumberInput className="uk-input" id="form-deal-to" type="Integer" value={this.state.dealHeaderIDTo} onChange={this.handleUpdateDealTo}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-deal-type">Deal type</label>
                                    <select className="uk-select" id="form-deal-type" value={this.state.dealType} onChange={this.handleUpdateDealType}>
                                        <option value="">Select a Type...</option>
                                        <option value="Spot">Spot</option>
                                        <option value="Forward">Forward</option>
                                        <option value="Drawdown">Drawdown</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount-from">Sell amount from</label>
                                    <NumberInput className="uk-input" id="form-sell-amount-from" type="Currency" scale={getScaleByCurrency(this.state.sellCurrency)} value={this.state.sellAmountFrom} onChange={this.handleUpdateSellAmountFrom}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount-to">Sell amount to</label>
                                    <NumberInput className="uk-input" id="form-sell-amount-to" type="Currency" scale={getScaleByCurrency(this.state.sellCurrency)} value={this.state.sellAmountTo} onChange={this.handleUpdateSellAmountTo}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-currency">Sell Currency</label>
                                    <input className="uk-input" id="form-sell-currency" value={this.state.sellCurrency} onChange={this.handleUpdateSellCurrency}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-buy-amount-from">Buy amount from</label>
                                    <NumberInput className="uk-input" id="form-buy-amount-from" type="Currency" scale={getScaleByCurrency(this.state.buyCurrency)} value={this.state.buyAmountFrom} onChange={this.handleUpdateBuyAmountFrom}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-buy-amount-to">Buy amount to</label>
                                    <NumberInput className="uk-input" id="form-buy-amount-to" type="Currency" scale={getScaleByCurrency(this.state.buyCurrency)} value={this.state.buyAmountTo} onChange={this.handleUpdateBuyAmountTo}/>
                                </div>
                                <div className="uk-width-1-3 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-buy-currency">Buy Currency</label>
                                    <input className="uk-input" id="form-buy-currency" value={this.state.buyCurrency} onChange={this.handleUpdateBuyCurrency}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-2 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-value-date-from">Value date from</label>
                                    <DatePicker
                                        className="uk-input" 
                                        onDayChange={this.handleUpdateValueDateFrom}
                                        value={this.state.valueDateFrom}/>
                                </div>
                                <div className="uk-width-1-2 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-value-date-from">Value date from</label>
                                    <DatePicker
                                        className="uk-input" 
                                        onDayChange={this.handleUpdateValueDateTo}
                                        value={this.state.valueDateTo}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-1 uk-form-controls uk-margin-top">
                                    <button className="uk-button uk-button-green uk-align-right" onClick={this.handleSearchClick}>Search</button>
                                </div>
                            </div>
                        </div>                    
                        <div>
                            <NewDynamicTable data={this.state.latestDeals} 
                                enableClick={this.state.enableClick}
                                clickFunction={this.props.handleDealClick}
                                useDeleteButton={false}
                                newSearch={this.state.latestDealsPagesNewSearch}
                                /*formats={[{header:'Buy Amount', formatFunction: formatToCurrency},
                                    {header:'Sell Amount', formatFunction:formatToCurrency}]}*/
                                />
                            <Paginator changePage={this.changeLatestDealsPage} 
                                numberOfPages={this.state.latestDealsPages} 
                                newSearch={this.state.latestDealsPagesNewSearch}/>
                        </div>
                    </div>
                );
                break;
            case 'drawdowns':
                dealListsView = (
                    <div className="uk-form-stacked">
                        <h4>
                            <button className="uk-button uk-button-text uk-margin-right" onClick={this.handleCloseDrawdownsClick}>
                                <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                            </button>                        
                            Drawdowns
                        </h4>
                        <NewDynamicTable data={this.state.drawdowns} 
                                enableClick={this.state.enableClick} 
                                clickFunction={this.props.handleDealClick}
                                newSearch={true}
                                useDeleteButton={false}
                                formats={[{header:'Buy Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Buy Currency'},
                                    {header:'Sell Amount', formatFunction:formatToCurrencyWithScale, formatCurrency: 'Sell Currency'}]}/>
                    </div>
                );
                break;
        }

        return(
            <div style={this.props.disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                {dealListsView}
            </div>
            );
    }
}